// material-ui
import React, { useState } from 'react'
// import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { Badge, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from '@mui/material';
import { useQuery } from 'react-query';
import UserAccounts from 'service/UserAccounts';

import ButtonMenus from 'ui-component/buttons/ButtonMenus';
import { Autorenew, HowToReg, Person, Upgrade } from '@mui/icons-material';
import ApproveModal from './modals/ApproveModal';
import { useNavigate } from 'react-router-dom';
import SuspendModal from './modals/SuspendModal';
import { IconSitemap } from '@tabler/icons';


function Customers (){
    const [customer, setCustomer] = useState({id:null, approval:null})
    const [approveModal, setApproveModal] = useState(false);
    const [suspendModal, setSuspendModal] = useState(false);

    let navigate = useNavigate()

    const handleOpenApprove = (id) => {
        setCustomer({...customer, id:id, approval:'signup'})
        setApproveModal(true);
    };
    const handleRenew = (id) => {
        setCustomer({...customer, id:id, approval:'renewal'})
        setApproveModal(true);
    }
    const handleUpgrade = (id) => {
        setCustomer({...customer, id:id, approval:'upgrade'})
        setApproveModal(true);
    }

    const handleCloseApprove = () => {
        setApproveModal(false);
    };

    const handleCustomerProfile = (id) => {
        navigate(`/admin/customer/profile/${id}`)
    };
    const handleCustomerNetwork = (id) => {
        navigate(`/admin/customer/network/${id}`)
    };
    

    // const handleOpenSuspend = (id) => {
    //     setCustomer(id)
    //     setSuspendModal(true);
    // };

    const handleCloseSuspend = () => {
        setSuspendModal(false);
    };

    const menus = [
        {
            func: handleCustomerProfile,
            icon: <Person sx={{ me:5 }}/>,
            title: 'View Details'
        },
        {
            func: handleCustomerNetwork,
            icon: <IconSitemap sx={{ me:5 }}/>,
            title: 'View Network'
        },
        {
            func: handleOpenApprove,
            icon: <HowToReg sx={{ me:5 }}/>,
            title: 'Approve Account'
        },
        {
            func: handleRenew,
            icon: <Autorenew sx={{ me:5 }}/>,
            title: 'Renew Account'
        },
        {
            func: handleUpgrade,
            icon: <Upgrade sx={{ me:5 }}/>,
            title: 'Upgrade Account'
        },
    ]

    const columns = [
        { id: 'fullname', label: 'Name', minWidth: 100},
        { id: 'email', label: 'Email', minWidth: 100 },
        { id: 'phone_number', label: 'Phone Number', minWidth: 100 },
        { id: 'status', label: 'Status', minWidth: 100, align: 'center', format: (value) => <Badge color={`${value==='active'?'primary':'warning'}`} badgeContent={value}/>},
        { id: 'is_activated', label: 'Approved', minWidth: 100, align: 'center', format: (value) => <Badge color={`${value===true?'primary':'warning'}`} badgeContent={value===true?'Yes':'No'}/>},
        { id: 'earnings', label: 'Level', minWidth: 100, align: 'center', format: (value) => <Chip label={`Level ${value?.level}`} variant="outlined" />},
        { id: 'earnings', label: 'Total Earnings', minWidth: 100, align: 'center', format: (value) => (`GHS ${value?.total_earnings}`)},
        { id: 'cashouts', label: 'Total Cashout', minWidth: 100, align: 'center', format: (value) => (`GHS ${value?.total_amount}`)},
        { id: 'id', label: 'Action', minWidth: 100, format: (value) => <ButtonMenus options={menus} id={value}/>},
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const {data: responseData, refetch} = useQuery("customers", UserAccounts.getUserCustomers);
    const users = responseData?.data?.results;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event?.target?.value);
        setPage(0);
    };


    
    return(
    
    <MainCard
    content={false}
    title="Customers" 
    >

    {/* table */}
    <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell sx={{ py: 3 }} key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow sx={{ py: 3 }} hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                            const value = column.id === 'fullname'? `${row?.first_name} ${row?.last_name}` : row[column.id];
                            return (
                                <TableCell key={column.id} align={column.align}>
                                    {column.format ? column.format(value) : value}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>

    {/* table pagination */}
    <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={users?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />

    <ApproveModal handleClose={handleCloseApprove}  open={approveModal} customer={customer} refetch={refetch}/>
    <SuspendModal handleClose={handleCloseSuspend}  open={suspendModal} user_id={customer?.id}/>

</MainCard>
)}

export default Customers;
