import { Box, Button, Divider, Grid, Snackbar, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import UserAccounts from 'service/UserAccounts'
import MainCard from 'ui-component/cards/MainCard'
import SubCard from 'ui-component/cards/SubCard'

export default function CustomerProfile(){
    
    const params = useParams()
    const userId = params?.id
    const {data} = useQuery(['customer-profile'], ()=> UserAccounts.getCustomer(userId))
    const customer = data?.data
    const referalLink = `${window.location.origin.toString()}/invite/${customer?.referral_code}`


    const [open, setOpen] = useState(false)
    const handleCopy = () => {
        setOpen(true)
        navigator.clipboard.writeText(referalLink)
    }


    return(
        <MainCard content={false} title="Customer Profile" >
            {open &&
                <Snackbar
                    open={open}
                    onClose={() => setOpen(false)}
                    autoHideDuration={2000}
                    message="Copied to clipboard"
                />
            }
            <Stack direction="row" justifyContent="end">
                <Box sx={{mx:5, py:1}}>
                <code>
                    Referal link:
                </code>
                <Tooltip title="Copy" arrow>
                    <Button sx={{ textTransform: 'lowercase' }} onClick={() => handleCopy()}>{referalLink}</Button>
                </Tooltip>
                </Box>
            </Stack>
            
            <Grid item xs={12}>
                <SubCard>
                    <Grid container direction="column" spacing={1}>
                        
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={3} sx={{mb:3}}>
                                    <Typography variant="subtitle2">First Name</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.first_name || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">Last Name</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.last_name || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">Other names</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.other_names || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">Gender</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.gender || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">Date of birth</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.date_of_birth || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">Email</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.email || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">Phone number</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.phone_number || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">Marital status</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.marital_status || 'N/A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{my:4}}/>
                    <Grid container direction="column" spacing={1}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={3} sx={{mb:3}}>
                                    <Typography variant="subtitle2">Postal Address</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.address || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">GPS Address</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.gps || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">Home town</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.home_town || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">Place of birth</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.place_of_birth || 'N/A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{my:3}}/>
                    <Grid container direction="column" spacing={1}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={3} sx={{mb:3}}>
                                    <Typography variant="subtitle2">Occupation</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.occupation || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">TIN</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.tin || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">ID TYPE</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.id_type || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">ID Number</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.id_number || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle2">SUM Assured</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.sum_assured || 'N/A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{my:3}}/>
                    <Grid container direction="column" spacing={1}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={3} sx={{mb:3}}>
                                    <Typography variant="subtitle2">Bank Account Name</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.bank_account?.account_name || 'N/A'}</Typography>
                                </Grid>
                               <Grid item xs={12} sm={3} sx={{mb:3}}>
                                    <Typography variant="subtitle2">Bank Name</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.bank_account?.bank_name || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} sx={{mb:3}}>
                                    <Typography variant="subtitle2">Bank Branch</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.bank_account?.bank_branch || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} sx={{mb:3}}>
                                    <Typography variant="subtitle2">Account Number</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.bank_account?.account_number || 'N/A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{my:3}}/>
                    <Grid container direction="column" spacing={1}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={3} sx={{mb:3}}>
                                    <Typography variant="subtitle2">Trustee Name</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.trustee?.trustee_name || 'N/A'}</Typography>
                                </Grid>
                               <Grid item xs={12} sm={3} sx={{mb:3}}>
                                    <Typography variant="subtitle2">Trustee Date of Birth</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.trustee?.trustee_date_of_birth || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} sx={{mb:3}}>
                                    <Typography variant="subtitle2">Trustee relationship</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.trustee?.trustee_relationship || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} sx={{mb:3}}>
                                    <Typography variant="subtitle2">Trustee Phone number</Typography>
                                    <Typography variant="subtitle1 h6">{customer?.trustee?.trustee_phone_number || 'N/A'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{my:3}}/>
                    <Grid container direction="column" spacing={1}>
                        {customer?.beneficiaries?.map((benf, idx) => 
                            <Grid item xs={12} key={idx}>
                                <Grid container>
                                    <Grid item xs={12} sm={3} sx={{mb:3}}>
                                        <Typography variant="subtitle2">Beneficiary Name</Typography>
                                        <Typography variant="subtitle1 h6">{benf?.beneficiary_name || 'N/A'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} >
                                        <Typography variant="subtitle2">Beneficiary Date of Birth</Typography>
                                        <Typography variant="subtitle1 h6">{benf?.beneficiary_date_of_birth || 'N/A'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} >
                                        <Typography variant="subtitle2">Beneficiary Date of Birth</Typography>
                                        <Typography variant="subtitle1 h6">{benf?.beneficiary_relationship || 'N/A'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="subtitle2">Beneficiary Phone number</Typography>
                                        <Typography variant="subtitle1 h6">{benf?.beneficiary_phone_number || 'N/A'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} >
                                        <Typography variant="subtitle2">Beneficiary Percentage</Typography>
                                        <Typography variant="subtitle1 h6">{benf?.beneficiary_percentage || 'N/A'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </SubCard>
            </Grid>
            
        </MainCard>
    )
}